import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  background-color: black;
  .footer-cover {
    width: 80%;
    /* height: 20vh; */
    color: white;
    display: flex;
    justify-content: space-evenly;
    align-content: center;
    align-items: center;
    @media (max-width: 707px) {
      /* height: 40px; */
      width: 100%;
      flex-direction: column;
    }
  }

  .social-icon {
    display: flex;
    justify-content: space-evenly;
    width: 50%;
    padding: 1rem;
  }
  .link {
    color: #707023;
    width: 100%;
    text-align: center;
  }
`;
