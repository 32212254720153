import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./App.css";
import Home from "./pages/home";
import Navbar from "./components/nav";
import Footer from "./components/footer";
import Contact from "./pages/contact";
import Bookflight from "./pages/bookFlight";
import Carepack from "./pages/carePack";
import Donate from "./pages/donate";
import leaveApplication from "./pages/leaveApplication";
import Medicalkit from "./pages/medicalKit";
import Soldierid from "./pages/soldierId";
import CallCards from "./pages/globalcall";

function App() {
  return (
    <BrowserRouter>
      <div>
      <div className="google-bar" style={{position:"fixed",bottom:0,padding:"2rem",backgroundColor:"white",borderRadius:"10px",display:"block",Zindex:9999}}>
        <p>Select language</p>   
        <div id="google_translate_element"></div>
    </div>
        <Navbar />
        <Switch>
          
          <Route path="/" exact={true} component={Home} />
          <Route path="/contactus" component={Contact} />
          <Route path="/bookflight" component={Bookflight} />
          <Route path="/carepack" component={Carepack} />
          <Route path="/donate" component={Donate} />
          <Route path="/leaveApplication" component={leaveApplication} />
          <Route path="/medicalkit" component={Medicalkit} />
          <Route path="/soldierid" component={Soldierid} />
          {/* <Route path="/callcard" component={CallCards} /> */}
        </Switch>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
