import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  .coverpage {
    background-color: #707023;
    width: 100%;
    height: 20vh;
    color: white;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
  }
  p {
    line-height: 200%;
    text-align: justify;
    width: 80%;
    color: #767a70;
    margin-bottom: 1.5rem;
    text-rendering: optimizelegibility;
  }
`;

export const SubTitle = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  align-content: center;

  h1 {
    margin: 0;
  }
  h3 {
    color: #767a70;
  }
`;

export const CustomButton = styled.button`
  background-color: #707023;
  border-radius: 3px;
  border: 0;
  color: white;
  height: 48px;
  padding: 0 30px;
  box-shadow: 0 3px 5px 2px rgba(154, 154, 91, 1);
  width: 100%;
  transition: 0.7s ease-out;
  outline: none;
  /* &:hover {
    color: #707023;
    /* background-color: white;
    border: 1px solid #707023;
  } */
`;
