import styled from "styled-components";
import badge from "../../images/badge1.jpg";

export const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 3rem;
  .coverpage {
    background-color: #707023;
    width: 100%;
    height: 20vh;
    color: white;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
  }
  h2 {
    text-align: center;
  }
  .soldier-wrapper {
    width: 100%;
    height: 40vh;
    /* background-image: url("./../images/badge1.jpg");
    background-position: cover;
    background-repeat: no-repeat;
    background-size: cover; */
    @media (max-width: 707px) {
      height: 50vh;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .soldierid-btn {
      position: relative;
      top: 12rem;
      left: 27rem;
      background: #707023;
      opacity: 1;
      border-radius: 7px;
      border: 0;
      font-weight: bold;
      color: white;
      height: 48px;
      padding: 0 30px;
      /* box-shadow: 0 3px 5px 2px rgba(154, 154, 91, 1); */
      outline: none;
      &:hover {
        opacity: 0.7;
      }
      @media (max-width: 707px) {
        top: 10rem;
        left: 2rem;
        height: 48px;
        opacity: 0.7;
        &:hover {
          opacity: 1;
        }
      }
    }
    /* .book-flight-btn::hover {
      border: 2px solid #707023;
    } */
  }
`;
