import React, { useEffect, useState } from "react";
import { useHistory, NavLink, Link, a } from "react-router-dom";
import { HeaderStyles } from "./navbar.styles";
import { BsList } from "react-icons/bs";
import logo1 from "../../images/logo.png";

// import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
// import SearchIcon from "@material-ui/icons/Search";

const NavBar = ({ children }) => {
  const history = useHistory();
  const [usernameIcon, SetusernameIcon] = useState("");
  const [state, setState] = useState("");
  let icon = JSON.parse(sessionStorage.getItem("user-data"));

  useEffect(() => {
    let d =
      icon && icon.username ? icon.username.split("")[0].toUpperCase() : "";
    SetusernameIcon(d);
  }, [icon]);

  const HandleLogout = (e) => {
    e.preventDefault();
    sessionStorage.removeItem("user-data");
    sessionStorage.removeItem("user-token");
    window.location.replace("/login");
  };

  const HandleChange = (e) => {
    setState(e.target.value);
  };

  const HandleNavbar = (e) => {
    let navbarbtn = document.querySelector(".navbar-lg ");

    navbarbtn.classList.toggle("toggle-navbar-sm");
  };

  return (
    <HeaderStyles children={children}>
      <div className="logoSearch">
        <div
          className="logo"
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            alignContent: "center",
            width: "100%",
            padding: "14px",
          }}
        >
          <img src={logo1} />
          <div className="navbar-sm-btn">
            <BsList
              fontSize="2rem"
              className="navbar-sm-btn"
              onClick={HandleNavbar}
            />
          </div>{" "}
        </div>
        <div
          className="navbar-lg"
          style={{
            width: "100%",
          }}
        >
          <ul>
            <li>
              <NavLink activeStyle={{ color: "#707023" }} exact to="/">
                HOME
              </NavLink>
            </li>
            <li>
              <NavLink activeStyle={{ color: "#707023" }} to="/carepack">
                CARE PACK
              </NavLink>
            </li>
            <li>
              <NavLink
                activeStyle={{ color: "#707023" }}
                to="/leaveapplication"
              >
                LEAVE APPLICATION
              </NavLink>
            </li>
            <li>
              <NavLink activeStyle={{ color: "#707023" }} to="/bookflight">
                BOOK FLIGHT
              </NavLink>
            </li>
            <li>
              <NavLink activeStyle={{ color: "#707023" }} to="/medicalkit">
                MEDICAL KIT
              </NavLink>
            </li>
            <li>
              <NavLink activeStyle={{ color: "#707023" }} to="/soldierid">
                SOLDIER ID
              </NavLink>
            </li>
            <li>
              <NavLink activeStyle={{ color: "#707023" }} to="/contactus">
                CONTACT US
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </HeaderStyles>
  );
};

export default NavBar;
