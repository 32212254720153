import React from "react";
import image1 from "../../images/homecoming.jpg";
import { Wrapper } from "./homeComing.styles";

const HomeComing = () => {
  return (
    <Wrapper>
      <div className="content-wrapper">
        <div className="column-1_7 sc_column_item sc_column_item_1 odd first"></div>
        <div className="text-wrapper">
          <h1 className="sc_title sc_title_regular ">HomeComing</h1>
          <h5
            className="sc_title sc_title_regular "
            style={{ marginTop: "1.85em", marginBottom: "0.9em" }}
          >
            Coming Home After Some Years
          </h5>
          <div className="wpb_text_column wpb_content_element ">
            <div className="wpb_wrapper">
              <p>
                Some days, 24 hours is too much to stay put in, so I take the
                day hour by hour, moment by moment. I break the task, the
                challenge, the fear into small, bite-size pieces. I can handle a
                piece of fear, depression, anger, pain, sadness, loneliness,
                illness. I actually put my hands up to my face, one next to each
                eye, like blinders on a horse. Every time I see a children
                holding hands with their parents, or just plainly sitting
                together with their parents, I look away. It’s not that I hate
                seeing pretty lovely things like that. But because it reminds me
                of a question nobody can answer…”Where’s mine?”
              </p>
            </div>
          </div>
        </div>
        <div className="image-wrapper">
          <img src={image1} alt="homecoming" />
        </div>
      </div>
    </Wrapper>
  );
};
export default HomeComing;
