import React from "react";

import DonateComponent from "../../components/donation";

const Donate = () => {
  return (
    <div>
      <span className="nav-span">
        <span style={{ color: "#707023" }}>
          HOME {""}
          {" > "}
          {""}
        </span>
        {""}
        DONATION
      </span>
      <DonateComponent />
    </div>
  );
};

export default Donate;
