import React from "react";
import Address from "../../images/address.jpeg";
import { makeStyles } from "@material-ui/core/styles";
import { Link, useHistory } from "react-router-dom";

import { Wrapper, SubTitle } from "./donation.styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    // justifyContent: "space-evenly",
    alignItems: "center",
    width: "80%",
    // background: "#f7a3b2",
    padding: theme.spacing(2),
    height: "90vh",
  },

  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    height: "100%",
  },
  submit: {
    margin: theme.spacing(2, 0),
    backgroundColor: "#e00707",
    height: "3rem",
  },
}));

const Donation = () => {
  const classes = useStyles();
  const history = useHistory();

  const HandleDone = (e) => {
    e.preventDefault();

    history.push("/");
  };

  const HandleConnect = (e) => {
    e.preventDefault();

    history.push("/contactus");
  };
  return (
    <Wrapper>
      <SubTitle>
        <div className="coverpage ">
          <h2>ANONYMOUS DONATION</h2>
        </div>
        <h3>Help Make A Difference By Donating Cryptocurrency</h3>
      </SubTitle>
      <center>
        <p>
          We've all seen how the suffering from the pandemic continues: job
          losses, isolation, uncertainty, health concerns and more. Your support
          is needed so our local facilities can continue to meet the daily needs
          of vulnerable populations. Your donation will help us to continue to
          respond with essential gifts of food, shelter and hope to those in
          need throughout your community.
        </p>
        <div style={{ width: "100%" }}>
          <div className="image-wrapper">
            <img src={Address} alt="wallet address" />
          </div>
        </div>
      </center>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <div className={classes.paper}>
          <form
            className={classes.form}
            noValidate
            // onSubmit={HandleSubmit}
          >
            <Grid className="add-product-div">
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  // sm={6}
                >
                  <TextField
                    autoComplete="fullname"
                    name="fullName"
                    variant="outlined"
                    required
                    fullWidth
                    id="productName"
                    value={"1MKNTwobRnUao2mFLxjujC1tVBGKR257E4"}
                    //   label="Applicant's Full Name"
                    //   autoFocus
                    onChange={HandleDone}
                    // placeholder={state.productName}
                    type="text"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="secondary"
              style={{ backgroundColor: "#707023" }}
              className={classes.submit}
              onClick={HandleDone}
            >
              Done
            </Button>
            <br />
            <br />
            <p style={{ width: "100%", textAlign: "center" }}>Or</p>
            <br />
            <br />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="secondary"
              style={{ backgroundColor: "#707023" }}
              className={classes.submit}
              onClick={HandleConnect}
            >
              CONTACT US
            </Button>
          </form>
        </div>
      </div>
    </Wrapper>
  );
};

export default Donation;
