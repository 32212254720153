import React from "react";
import MedicalKitComponent from "../../components/medicalKit";
const Medicalkit = () => {
  return (
    <div>
      <span className="nav-span">
        <span style={{ color: "#707023" }}>
          HOME{""}
          {" > "}
          {""}
        </span>
        {""}MEDICAL KIT
      </span>
      <MedicalKitComponent />
    </div>
  );
};

export default Medicalkit;
