import React from "react";
// import "../../App.css";
import SoldierIdComponent from "../../components/soldierId";
const SoldierId = () => {
  return (
    <div>
      <span className="nav-span">
        <span style={{ color: "#707023" }}>
          HOME{""}
          {" > "}
          {""}
        </span>
        {""}SOLDIER ID
      </span>
      <SoldierIdComponent />
    </div>
  );
};

export default SoldierId;
