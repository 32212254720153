import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Wrapper, SubTitle, CustomButton } from "./leaveApplication.styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { useToasts } from "react-toast-notifications";
import MenuItem from "@material-ui/core/MenuItem";
import Axios from "axios";

// const url = "localhost:2000";
const url = "https://server.unitedstatemilitary.com";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    // justifyContent: "space-evenly",
    alignItems: "center",
    width: "80%",
    // background: "#f7a3b2",
    padding: theme.spacing(2),
    // height: "90vh",
  },

  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    // height: "100%",
  },
  submit: {
    margin: theme.spacing(2, 0),
    backgroundColor: "#e00707",
  },
}));

const LeaveApplication = () => {
  const classes = useStyles();
  const { addToast } = useToasts();
  let initialState = {
    fullName: "",
    phoneNumber: "",
    email: "",
    soldierFullName: "",
    soldierRank: "",
    description: "",
  };

  const [state, Setstate] = useState(initialState);
  const [sending, setSending] = useState(false);

  const HandleInputChange = (e) => {
    const { name, value } = e.target;
    Setstate({ ...state, [name]: value });
  };

  const [Rank, SetRank] = useState([
    "Military Doctor",
    "Private  Class",
    "Private First Class",
    "Specialist",
    "Corporal Sergeant",
    "Staff Sergeant",
    "Sergeant First Class",
    "Master Sergeant",
    "First Sergeant",
    "Sergeant Major",
    "Command Sergeant Major",
    "Sergeant Major of the Army",
  ]);

  const HandleSubmit = (e) => {
    e.preventDefault();
    setSending(true);
    document.querySelector("#btn").innerHTML = "Sending...";
    Axios.post(`${url}/leaveapplication`, state)
      .then((info) => {
        addToast("successfully sent", {
          appearance: "success",
          autoDismiss: true,
        });
        setSending(false);
        document.querySelector("#btn").innerHTML = "Submit";
        Setstate(initialState);
      })
      .catch((err) => {
        addToast(err.message, {
          appearance: "error",
          autoDismiss: true,
        });
        setSending(false);
        document.querySelector("#btn").innerHTML = "Submit";
        // Setstate(initialState);
      });
    
  };

  return (
    <Wrapper>
      <SubTitle>
        <div className="coverpage">
          <h1>LEAVE APPLICATION</h1>
        </div>
        <br />
        <h3>Attention To All Applicants</h3>
      </SubTitle>
      <div style={{ width: "100%" }}>
        <center>
          <p>
            The U.S Army Board of the Welfare Department has made the new rules
            for the expatriates in war zones requesting for a Work Leave to be
            applied by their loved ones/friends/relatives for the reasons below.
            <br />
            <b>Health Leave:</b> The health leave is the visit whereby an
            expatriate/soldier under the U.S Army Organization is not in Good
            health and his loved ones/friends/relatives may need him home for
            proper treatment.
            <br />
            <b>Marital Leave:</b> This leave is whereby the Government gives an
            expatriate/soldier opportunity to leave the war zone to meet up with
            their fiancee for marriage purpose.
            <br />
            For the Leave to be approved, the loved ones/friends/relatives who
            is applying for the Leave must remit a refundable fee which is
            sometimes a percentage off his/her allowance, and also for the
            welfare of other expatriates that will replace duty posts to
            continue to serve our beloved country.
            <br />
            <br />
          </p>
        </center>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <div className={classes.paper}>
            {/* <SubTitle> */}
            <h2 style={{ textAlign: "center" }}>LEAVE APPLICATION FORM</h2>
            {/* </SubTitle> */}
            <form className={classes.form} noValidate onSubmit={HandleSubmit}>
              <Grid className="add-product-div">
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    // sm={6}
                  >
                    <TextField
                      autoComplete="fullname"
                      name="fullName"
                      variant="outlined"
                      required
                      fullWidth
                      id="productName"
                      value={state.fullName}
                      label="Applicant's Full Name"
                      autoFocus
                      onChange={HandleInputChange}
                      // placeholder={state.productName}
                      type="text"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="select"
                      label="Applicant's Phone Number"
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      value={state.phonenumber}
                      onChange={HandleInputChange}
                      name="phoneNumber"
                      // placeholder={state.collectionName}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="select"
                      label="Applicant's Email"
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      value={state.email}
                      onChange={HandleInputChange}
                      name="email"
                      type="email"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="select"
                      label="Select Soldier's Rank"
                      variant="outlined"
                      required
                      fullWidth
                      value={state.soldierRank}
                      onChange={HandleInputChange}
                      name="soldierRank"
                      // placeholder={"state.categoryName"}
                      select
                    >
                      {Rank &&
                        Rank.map((item, id) => {
                          return (
                            <MenuItem key={id} value={item}>
                              {item}
                            </MenuItem>
                          );
                        })}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      name="soldierFullName"
                      type="text"
                      value={state.soldierFullName}
                      onChange={HandleInputChange}
                      label="Soldier's Full Name"
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    // sm={6}
                  >
                    <TextField
                      autoComplete="description"
                      name="description"
                      variant="outlined"
                      rows={4}
                      required
                      multiline
                      fullWidth
                      id="description"
                      label="Tell us the reason you are applying for Army's Leave and how long."
                      value={state.description}
                      onChange={HandleInputChange}
                    />
                  </Grid>{" "}
                </Grid>
              </Grid>

              <CustomButton
                type="submit"
                fullWidth
                variant="contained"
                color="secondary"
                style={{ backgroundColor: "#707023" }}
                className={classes.submit}
                disabled={sending}
                id="btn"
                // onClick={HandleSubmit}
              >
                Submit
              </CustomButton>
            </form>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default LeaveApplication;
