import React from "react";
import { useHistory } from "react-router-dom";
import { SubTitle, Wrapper, CustomButton } from "./bookflight.styles";
import flight from "../../images/flight.jpg";

const BookFlight = () => {
  const history = useHistory();

  const HandleBooking = (e) => {
    e.preventDefault();

    history.push("/contactus");
  };
  return (
    <Wrapper>
      <SubTitle>
        <div className="coverpage">
          <h1>BOOK A FLIGHT</h1>
        </div>
        <br />
        <h3>U.S. MILLITARY FLIGHT</h3>
      </SubTitle>
      <div style={{ width: "100%" }}>
        <center>
          <p>
            United States Army Welfare Unit offers cheap tickets for active duty
            and retired US Military Personnel. We offer discounted tickets for
            domestic and international flights. Do you need a flight home for
            leave, are you planning a family vacation, or do you need an
            emergency ticket somewhere? We are able to offer you exclusive
            military travel financing with no down payment required!
            <br />
            Not enough space on a Space A military flight? Can’t find military
            fares online? Sometimes certain commercial airlines may not carry a
            military discount or there are only a certain number of fares
            available for military discounts. We have travel consultants who
            have special search engines that can search for military fares and
            if those discounted military fares are not available, we will offer
            you the cheapest fare option available.
            <p />
            <SubTitle>
              {" "}
              <h3>OUR HISTORY</h3>
            </SubTitle>
            We specialize in financing the cheapest fares for military and
            government personnel, which we back with our price match guarantee!
            We offer travel financing programs for active duty military, retired
            military, and US government employees. We offer military travel
            financing options that are paid in monthly installments and we can
            even accept monthly payments with military allotments.
            <br />
          </p>
        </center>
        <div className="book-flight-wrapper">
          <button className="book-flight-btn" onClick={HandleBooking}>
            CONTACT US FOR FLIGHT BOOKING
          </button>
          <img src={flight} alt="flight" />
        </div>
      </div>
    </Wrapper>
  );
};

export default BookFlight;
