import styled from "styled-components";

export const HeaderStyles = styled.header`
  background: #19191c;
  height: max-content;
  color: #caccbf;
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0px;
  z-index: 9999;
  /* overflow: hidden; */
  /* position: fixed; */
  /* top: 0; */
  flex-wrap: wrap;
  padding: 0px 50px;
  /* background-size: contain; */
  background-repeat: no-repeat;

  @media (max-width: 450px) {
    padding: 0 10px;
  }

  .logoSearch {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    /* border: 2px solid red; */
    /* height: max-content; */
    @media (max-width: 450px) {
      justify-content: center;
    }
    .navbar-lg {
      @media (max-width: 707px) {
        display: none;
      }
    }
    .navbar-lg {
      /* display: none; */
      @media (max-width: 707px) {
        display: none;
      }
    }
    .toggle-navbar-sm {
      display: block;
    }

    .input {
      width: 300px;
      height: 30px;
      background: #fff;
      padding: 2px 10px;
      outline: none;
      text-indent: 10px;
      border-radius: 20px;
      border: 2px solid white;
    }
    .navbar-sm-btn {
      display: none;
      @media (max-width: 707px) {
        display: block;
      }
    }
  }

  .logo {
    display: flex;
    align-items: center;

    span {
      /* padding: 0; */
      width: 50px;
      text-align: center;
      background: #e00707;
      line-height: 50px;
      font-size: 50px;
      font-weight: 700;
    }
  }

  ul {
    list-style: none;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    padding: 0;
    margin: 0;
  }

  .accountIcon {
    width: 30px;
    height: 30px;
    font-weight: 700;
    background-color: red;
    border-radius: 50%;
    align-items: center;
    display: flex;
    justify-content: center;
  }

  li {
    /* width: 70px; */
    padding: 10px;
    border-bottom: 2px solid transparent;
    cursor: pointer;

    :hover {
      border-bottom: 2px solid #707023;
    }
    :focus {
      border-bottom: 2px solid #707023;
    }
    .register {
      display: none;
      position: absolute;
      top: 100px;
      background: #19191c;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      color: white;
      z-index: 999;
      width: 100px;
      /* padding: 0 10px 0 10px; */
      /* right: 12.1%; */
      right: auto;

      li:hover {
        background-color: #e00707;
      }

      @media (max-width: 630px) {
        top: 135px;
        right: auto;
        width: 100px;
      }
      @media (max-width: 580px) {
        top: 175px;
        right: auto;
        width: 100px;
      }
      @media (max-width: 380px) {
        top: 170px;
        right: auto;
        width: 100px;
      }
    }

    a {
      color: #caccbf;
      text-decoration: none;
    }
    :hover .register {
      display: block;
      cursor: pointer;
    }
  }
`;
