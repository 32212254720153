import styled from "styled-components";

export const Wrapper = styled.div`
  p {
    line-height: 150%;
    text-align: justify;
    width: 80%;
    color: #767a70;
    margin-bottom: 1.5rem;
    text-rendering: optimizelegibility;
  }
  width: 100%;
  .coverpage {
    background-color: #707023;
    width: 100%;
    height: 20vh;
    color: white;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
  }

  .image-wrapper {
    width: 20%;
    @media (max-width: 707px) {
      width: 60%;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
`;

export const SubTitle = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  align-content: center;
  .donate-cover {
    background-color: #707023;
    width: 100%;
    height: 20vh;
    color: white;
    display: flex;
    justify-content: space-evenly;
    align-content: center;
    align-items: center;
    flex-direction: column;
  }
  .coverpage {
    background-color: #707023;
    width: 100%;
    height: 20vh;
    color: white;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
  }

  h1 {
    margin: 0;
  }
  h2 {
    margin: 0;
  }
  h3 {
    color: #767a70;
    text-align: center;
  }
`;
