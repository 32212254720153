import React from "react";
import LeaveApplicationComponent from "../../components/leaveApplication";
const LeaveApplication = () => {
  return (
    <div>
      <span className="nav-span">
        <span style={{ color: "#707023" }}>
          HOME{""}
          {" > "}
          {""}
        </span>
        {""}LEAVE APPLICATION
      </span>

      <LeaveApplicationComponent />
    </div>
  );
};

export default LeaveApplication;
