import styled from "styled-components";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

export const Wrapper = styled.div`
  width: 100%;

  .image-wrapper {
    width: 40%;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    @media (max-width: 707px) {
      width: 80%;
    }
  }
  .text-wrapper {
    width: 40%;
    text-align: justify;
    color: #767a70;
    margin-bottom: 1.5rem;
    text-rendering: optimizelegibility;
    line-height: 150%;
    @media (max-width: 707px) {
      width: 80%;
    }
  }
  .content-wrapper {
    display: flex;
    margin-bottom: 3rem;
    justify-content: space-evenly;
    @media (max-width: 707px) {
      flex-direction: column;
      align-items: center;
      align-content: center;
    }
  }
`;
