import styled from "styled-components";
import Button from "@material-ui/core/Button";

export const Wrapper = styled.div`
  width: 100%;
  /* background-color: red; */
  p {
    line-height: 200%;
    text-align: justify;
    width: 80%;
    color: #767a70;
    margin-bottom: 1.5rem;
    text-rendering: optimizelegibility;
  }
  .coverpage {
    background-color: #707023;
    width: 100%;
    height: 20vh;
    color: white;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
  }
  .book-flight-wrapper {
    width: 100%;
    height: 40vh;
    margin-bottom: 3rem;
    @media (max-width: 707px) {
      /* height: 50vh; */
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .book-flight-btn {
      position: relative;
      top: 12rem;
      left: 27rem;
      background: #707023;
      opacity: 1;
      border-radius: 7px;
      border: 0;
      font-weight: bold;
      color: white;
      height: 48px;
      padding: 0 30px;
      box-shadow: 0 3px 5px 2px rgba(154, 154, 91, 1);
      outline: none;
      &:hover {
        opacity: 0.7;
      }
      @media (max-width: 707px) {
        top: 10rem;
        left: 4rem;
        height: 48px;
        opacity: 0.7;
        &:hover {
          opacity: 1;
        }
      }
    }
    /* .book-flight-btn::hover {
      border: 2px solid #707023;
    } */
  }
`;
export const CustomButton = styled(Button)({
  background: "#707023",
  borderRadius: 3,
  border: 0,
  color: "white",
  height: 48,
  padding: "0 30px",
  boxShadow: "0 3px 5px 2px rgba(154,154,91,1)",
  width: "50%",
});

export const SubTitle = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  align-content: center;

  h1 {
    margin: 0;
  }
  h3 {
    color: #767a70;
  }
`;
