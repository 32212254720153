import React from "react";
import ContactForm from "../../components/contact";
const Contact = () => {
  return (
    <div>
      <span className="nav-span">
        <span style={{ color: "#707023" }}>
          HOME{""}
          {" > "}
          {""}
        </span>
        {""}CONTACT US
      </span>
      <ContactForm />
    </div>
  );
};

export default Contact;
