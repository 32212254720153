import React from "react";
import CarepackComponent from "../../components/carePack";

const Carepack = () => {
  return (
    <div>
      <span className="nav-span">
        <span style={{ color: "#707023" }}>
          HOME {""}
          {" > "}
          {""}
        </span>
        {""}
        CARE PACK
      </span>
      <CarepackComponent />
    </div>
  );
};

export default Carepack;
