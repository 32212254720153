import styled from "styled-components";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

export const Iframe = styled.iframe`
  max-width: 100%;
  min-height: 100px;
  vertical-align: top;
  width: 100%;
  height: 40vh;
  border: none;
  background-color: gray;
`;

export const useStyles = makeStyles((theme) => ({
  // root: {
  //   '& .MuiOutlinedInput-root':{
  //     '& fieldset':{
  //       borderColor:'#e00707'
  //     },
  //     '&.Mui-focused fieldset':{
  //       borderColor:'#e00707'
  //     }
  //   }
  // }
  // button: {
  //   "&:hover": {
  //     backgroundColor: "#e00707",
  //   },
  // },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "#e00707",
  },
  form: {},

  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#707023",
    textAlign: "center",
    cursor: "process",
  },
}));
export const CustomButton = styled.button`
  background-color: #707023;
  border-radius: 3px;
  border: 0;
  color: white;
  height: 48px;
  padding: 0 30px;
  box-shadow: 0 3px 5px 2px rgba(154, 154, 91, 1);
  width: 50%;
  transition: 0.7s ease-out;
  outline: none;
  &:hover {
    color: #707023;
    background-color: white;
    border: 1px solid #707023;
  }
`;

export const Wrapper = styled.main`
  width: 100%;

  .textfield-input::focus {
    border-color: #e00707;
  }
  .textfield-input:hover {
    border-color: none;
  }
  form {
    width: 50%;
    @media (max-width: 707px) {
      width: 80%;
    }
  }

  .form-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    flex-direction: column;
  }
  .textarea-input {
    width: 99%;
    /* border-color: rgb(238, 238, 238); */

    @media (max-width: 707px) {
      width: 96%;
    }
  }
`;
