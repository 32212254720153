import React from "react";
import { FaFacebook } from "react-icons/fa";
import { AiFillTwitterCircle, AiFillInstagram } from "react-icons/ai";
import { Wrapper } from "./footer.styles";
const Footer = () => {
  return (
    <Wrapper>
      <div className="footer-cover">
        <div className="social-icon">
          <div className="sc_socials_item">
            <a
              href="https://www.facebook.com/USArmy"
              target="_blank"
              className="social_icons social_facebook"
            >
              <span className="icon-facebook">
                <FaFacebook color="#EAEDEF" />
              </span>
            </a>
          </div>
          <div className="sc_socials_item">
            <a
              href="https://twitter.com/USArmy"
              target="_blank"
              className="social_icons social_twitter"
            >
              <span className="icon-twitter">
                <AiFillTwitterCircle color="#EAEDEF" />
              </span>
            </a>
          </div>
          <div className="sc_socials_item">
            <a
              href="https://instagram.com/usarmy"
              target="_blank"
              className="social_icons social_instagramm"
            >
              <span className="icon-instagramm">
                <AiFillInstagram color="#EAEDEF" />
              </span>
            </a>
          </div>
        </div>{" "}
        {/* <div className="copyright_text">
          <p> */}
        <a href="/" target="_blank" rel="noopener" className="link">
          unitedstatesmilitary-walfaredepartment.com ©{" "}
          {new Date().getFullYear()} . All rights reserved.
        </a>
        {/* </p>
        </div> */}
      </div>
    </Wrapper>
  );
};
export default Footer;
