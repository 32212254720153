import React, { useState } from "react";
import Axios from "axios";
import { Iframe, useStyles, CustomButton, Wrapper } from "./contact.styles";
import TextField from "@material-ui/core/TextField";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Grid from "@material-ui/core/Grid";
import { useToasts } from "react-toast-notifications";

const url = "https://server.unitedstatemilitary.com";
// const url = "localhost:2000";

const ContactForm = () => {
  let initialState = {
    fullName: "",
    email: "",
    message: "",
  };
  const classes = useStyles();
  const [state, Setstate] = useState(initialState);
  const [sending, setSending] = useState(false);

  const { addToast } = useToasts();

  const HandleInputChange = (e) => {
    const { name, value } = e.target;
    Setstate({ ...state, [name]: value });
  };

  const HandleSubmit = (e) => {
    e.preventDefault();
    setSending(true);
    document.querySelector("#btn").innerHTML = "Sending...";
    // Axios.post(`${url}/contact`, state)
    //   .then((info) => {
    //     addToast(info.data, {
    //       appearance: "success",
    //       autoDismiss: true,
    //     });
    //     setSending(false);
    //     document.querySelector("#btn").innerHTML = "Submit";
    //     Setstate(initialState);
    //   })
    //   .catch((err) => {
    //     addToast(err.message, {
    //       appearance: "error",
    //       autoDismiss: true,
    //     });
    //     setSending(false);
    //     document.querySelector("#btn").innerHTML = "Submit";
    //   });
    addToast("successfully sent", {
      appearance: "success",
      autoDismiss: true,
    });
    setSending(false);
    document.querySelector("#btn").innerHTML = "Submit";
    Setstate(initialState);
  };

  return (
    <Wrapper>
      <Iframe
        src="https://maps.google.com/maps?t=m&output=embed&iwloc=near&z=12&q=Fort+Bragg"
        scrolling="no"
        marginheight="0"
        marginwidth="0"
        frameborder="0"
        aria-label=""
        loading="lazy"
        allowFullScreen
      ></Iframe>
      <div className="form-wrapper">
        {/* <center> */}
        <label style={{ padding: "3rem" }}>CONTACT US</label>

        <form
          id="id-form"
          className={classes.form}
          noValidate
          onSubmit={HandleSubmit}
        >
          <Grid item xs={15}>
            <TextField
              variant="outlined"
              onChange={HandleInputChange}
              margin="normal"
              required
              fullWidth
              id="fullName"
              label="Full Name"
              name="fullName"
              autoComplete="text"
              placeholder={state.fullname}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Email Address"
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              name="email"
              autoComplete="email"
              className="textfield-input"
              onChange={HandleInputChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextareaAutosize
              rowsMin="4"
              rowsMax="7"
              minLength="20"
              label="Message"
              variant="outlined"
              // margin="normal"
              required
              className="textarea-input"
              id="message"
              name="message"
              autoComplete="text"
              onChange={HandleInputChange}
              placeholder="Enter Your Message"
            />
          </Grid>
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <CustomButton
              // onClick={HandleProfileUpdate}
              type="submit"
              variant="contained"
              color="secondary"
              className={classes.submit}
              id="btn"
              disabled={sending}
            >
              Submit
            </CustomButton>
          </div>
        </form>
      </div>
      {/* </center> */}
    </Wrapper>
  );
};

export default ContactForm;
