import React from "react";
import { useHistory } from "react-router-dom";
import { SubTitle } from "../bookFlight/bookflight.styles";
import { Wrapper } from "./soldierId.styles";
// import badge from "../../images/flight.jpg";
import badge from "../../images/badge3.jpg";

const SoldierId = () => {
  const history = useHistory();

  const HandleBooking = (e) => {
    e.preventDefault();

    history.push("/contactus");
  };
  return (
    <Wrapper>
      <SubTitle>
        <div className="coverpage">
          <h2>SOLDIER VERIFICATION</h2>
        </div>
        <br />
        <br />
        <h3>VERIFY U.S SOLDIER IDENTIFICATION</h3>
      </SubTitle>
      <div className="soldier-wrapper">
        <button className="soldierid-btn" onClick={HandleBooking}>
          CONTACT US FOR SOLDIER IDENTIFICATION
        </button>
        <img src={badge} alt="badge" />
      </div>
    </Wrapper>
  );
};

export default SoldierId;
