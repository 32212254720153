import React, { useState } from "react";
import "./homecard.styles";
import { useHistory } from "react-router-dom";
import medical from "../../images/medical.jpg";
import home from "../../images/images.jpeg";
import carePack from "../../images/care.jpeg";
import badge from "../../images/id.jpg";
import call from "../../images/call.jpg";
import flight from "../../images/flight.jpg";
import { Wrapper, SubTitle } from "./homecard.styles";
const HomeCard = () => {
  const [state, setState] = useState(false);
  let history = useHistory();
  const HandleRoute = (route) => {
    history.push(`/${route}`);
  };

  return (
    <Wrapper>
      <SubTitle>
        <label> OUR WORLD CLASS SERVICES</label>
      </SubTitle>
      <center>
        <div className="cards-Wrapper">
          <figure
            onClick={() => HandleRoute("medicalkit")}
            className="card-div"
          >
            <img src={medical} alt="medical" />
            <div className="title">
              <span>MEDICAL KIT</span>
            </div>
          </figure>
          <figure
            className="card-div"
            onClick={() => HandleRoute("leaveapplication")}
          >
            <img src={home} alt="medical" />
            <div>
              <span>LEAVE APPLICATION</span>
            </div>
          </figure>
          <figure className="card-div" onClick={() => HandleRoute("soldierid")}>
            <img src={badge} alt="medical" />
            <div>
              <span>SOLIDIER ID</span>
            </div>
          </figure>
          <figure
            className="card-div"
            onClick={() => HandleRoute("bookflight")}
          >
            <img src={flight} alt="medical" />
            <div>
              <span>BOOK FLIGHT</span>
            </div>
          </figure>
          {/* <figure className="card-div" onClick={() => HandleRoute("callcard")}>
            <img src={call} alt="medical" />
            <div>
              <span>GLOBAL CALL CARD</span>
            </div>
          </figure> */}
          <figure className="card-div" onClick={() => HandleRoute("carepack")}>
            <img
              src={carePack}
              alt="medical"
              style={{ objectFit: "contain" }}
            />
            <div>
              <span>CARE PACK</span>
            </div>
          </figure>

          {/* <ReactCardFlip isFlipped={state.isFlipped} flipDirection="vertical">
          <div >
            This is the front of the card.
            <button>Click to flip</button>
          </div>

          <div >This is the back of the card.</div>
        </ReactCardFlip> */}
          {/* <ReactCardFlip isFlipped={state.isFlipped} flipDirection="vertical">
          <div>
            This is the front of the card.
            <button >Click to flip</button>
          </div>

          <div>
            This is the back of the card.
            <button >Click to flip</button>
          </div>
        </ReactCardFlip> */}
        </div>
      </center>
    </Wrapper>
  );
};

export default HomeCard;
