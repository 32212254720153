import React from "react";
import Header from "../../components/header";
import HomecomingComponent from "../../components/homeComing";
import HomecardComponent from "../../components/homeCard";
import Button from "@material-ui/core/Button";
import { SubTitle } from "../../components/carePack/carePack.styles";
import { Link, useHistory } from "react-router-dom";

const Home = () => {
  const history = useHistory();

  const HandleDonate = (e) => {
    e.preventDefault();

    history.push("/donate");
  };
  return (
    <div>
      <Header />
      <HomecardComponent />
      <HomecomingComponent />
      <SubTitle>
        <div className="donate-cover">
          <h2>DONATE TO THE MILLITARY</h2>
          <Button
            type="submit"
            // fullWidth
            variant="contained"
            color="secondary"
            style={{ backgroundColor: "black" }}
            // className={classes.submit}
            onClick={HandleDonate}
          >
            Donate
          </Button>
        </div>
        <br />
      </SubTitle>
    </div>
  );
};

export default Home;
