import React from "react";
import { Link, useHistory } from "react-router-dom";
import { Wrapper, SubTitle } from "./carePack.styles";

const Carepack = () => {
  const history = useHistory();

  const HandleOrders = (e) => {
    e.preventDefault();

    history.push("/contactus");
  };
  return (
    // <div className="content">
    <Wrapper>
      <SubTitle>
        <div className="coverpage">
          <h1>CARE PACK</h1>
        </div>
        <br />
        <h3>Thank You for your support</h3>
      </SubTitle>
      <div style={{ width: "100%" }}>
        <center>
          <p
          // style={{
          //   lineHeight: "200%",
          //   textAlign: "justify",
          //   textJustify: "inter-word",
          // }}
          >
            Since December 2000, we’ve supported approximately ONE MILLION
            deployed U.S. troops with loving care packages to different war zone
            destinations around the world. This was made possible only with the
            help of tens of thousands of generous and patriotic friends in the
            U.S. and abroad. To date, we’ve sent more than 188,000 enormous
            boxes and more than 40 million letters and cards from caring
            citizens. This is the least we can do to show our troops how much we
            care about them. Our troops need to know that we support and
            appreciate the sacrifices they make for us, our families, and our
            future. This is about giving back to those who give all™.
            <br />
            <br />
            One of our most popular support efforts here at U.S. Army Care
            Service is sending care packages to our deployed troops. We send a
            touch of home to those who are deployed and serving in harm’s way on
            our behalf. We send out thousands of packages each year to various
            war zones through the different welfare countries.
            <br />
            <br />
            <b>We have different care packages that are in stock now:</b> <br />
            1. <b>Troops Pass Time Package - $500.00 Includes:</b>
            <br />
            Sweaters, Pack of underwears, Cotton Socks, Vitamins, Pack of
            Aspirin, pain Reliever, Toothbrush, Toothpaste, Shaving lotion,
            Laundry Detergent, Lip balm, Deodorant, Body spray, Hand warmer,
            T-shirts, Snack Cakes, Cheese Crackers, Cookies, Energy Bars.
            <br />
            <Link to="/contactus">
              <input
                className="order-btn"
                type="button"
                name=""
                value="Buy Now"
                // onClick={HandleOrders}
              />
            </Link>
            <br />
            <br />
            2. <b>Troops Combo Package - $1080.00 Includes:</b>
            <br />
            Sweaters, Pack of underwears, Cotton Socks, Vitamins, Pack of
            Aspirin, Medicated foot powder, Shampoo, Kleenex, Laundry detergent,
            Tootbrush, Dental Floss, Shaving Lotion, Lip balm, Hand warmer,
            T-shirts, Crossed Puzzles, MP3 Player/CDs, Snacks Cakes, Cheese
            Crackers, Cookies, Energy Bars. <br />
            <Link to="/contactus">
              <input
                className="order-btn"
                type="button"
                name=""
                value="Buy Now"
                onClick={HandleOrders}
              />
            </Link>
            <br />
            <br />
            3. <b>Troops Home Size Package - $2670.00 Includes:</b>
            <br /> Sweater, Pack of underwear, T-shirts, Fingerless gloves,
            Stocking cap, Instant Coffee, Tea bags, Snack Cakes, Cheese
            Crackers, Electrolyte replacements, Tuna Fish, Sardines, Shaving
            lotion, Shampoo, Kleenex, Laundry detergent, Body spray (Gillete,
            etc) Lotion, Lip balm, Hand warmer, Vitamins, Pack of Aspirin,
            Canned Foods (mix) Crossed Puzzles, MP3 Players/CD.
            <br />
            <Link to="/contactus">
              <input
                className="order-btn"
                type="button"
                name=""
                value="Buy Now"
                onClick={HandleOrders}
              />
            </Link>
            <br />
            <br />
            4. <b>Troops All In One Package - $5750.00 Includes:</b>
            <br />
            Canned Foods (All Mixed), Electrolyte replacement mix, Beverages,
            Instant Coffee, Tea bags, Creamer, Ketchup, Energy Bars, Tuna Fish,
            Sardines, Corn Beef, Snack Cakes, Cheese Crackers, Cookies,
            Toothbrush, Tootpaste, Mouthwash, Dental floss, Cotton Swabs,
            Shaving Lotion, Disposable razors, Kleenex, Shampo, Laundry
            detergent, Eye drops, Lip balm, Lotion, Deodorant/Body Spay, Pack of
            Aspirin or other pain reliever, medicated foot powder, Athlete’s
            Foot ointment, Underwears, T-shirts, Hand warmers, Goggle-style
            sunglasses, Cotton socks, Fingerless Gloves, Stocking caps, MP3
            Players/CD with batteries, Magazines, Crossed Puzzles.
            <br />
            <Link to="/contactus">
              <input
                className="order-btn"
                type="button"
                name=""
                value="Buy Now"
                onClick={HandleOrders}
              />
            </Link>
            <br />
            <br />
            <br />
            Please be notified that all Care Packages that have been paid for
            will be shipped to the different locations from our various United
            States Welfare countries due to ongoing crisis in those particular
            locations. Also you will need to provide Full names, Base being
            deployed, and Rank of any soldier you are purchasing a Care Package
            for to avoid delay in shipping. We want to let you know that each of
            the Care Package contains different items considering your purchase,
            and all items are being supplied to the soldier as packed.
            <br />
            For more information please go to the Contact page, or email us.
            <br />
            <br />
          </p>
        </center>
      </div>
    </Wrapper>
  );
};

export default Carepack;
