import React from "react";
import BookflightComponent from "../../components/bookFlight";

const BookFlight = () => {
  return (
    <div>
      <span className="nav-span">
        <span style={{ color: "#707023" }}>
          HOME {""}
          {">"}
          {""}
        </span>{" "}
        BOOK FLIGHT
      </span>
      <BookflightComponent />
    </div>
  );
};

export default BookFlight;
