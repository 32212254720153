import React from "react";
import NavBar from "../nav";
import image1 from "../../images/02.jpg";
import image2 from "../../images/armored.jpg";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const Header = () => {
  return (
    <div>
      {/* <NavBar/> */}
      <div className="imageDiv">
        <Carousel
          className="carousel"
          autoPlay={true}
          infiniteLoop
          showThumbs={false}
          showIndicators={false}
          showStatus={false}
          autoFocus
        >
          <div className="carousel-image-div" width="100%">
            <img
              width="100%"
              height="100%"
              // key={key}
              src={image1}
              className="shownow-slide-img"
              alt="manshoppingfortuxedo"
              rel="preload"
            />
          </div>
          <div className="carousel-image-div" width="100%">
            <img
              width="100%"
              height="100%"
              // key={key}
              src={image2}
              className="shownow-slide-img"
              alt="manshoppingfortuxedo"
              rel="preload"
            />
          </div>
        </Carousel>
      </div>
    </div>
  );
};

export default Header;
