import React from "react";
import "./medicalKit.styles";
import { Wrapper, SubTitle } from "./medicalKit.styles";
import { Link, useHistory } from "react-router-dom";

const MedicalKit = () => {
  const history = useHistory();

  const HandleOrders = (e) => {
    e.preventDefault();

    history.push("/contactus");
  };
  return (
    <Wrapper>
      <SubTitle>
        <div className="coverpage">
          <h1>MEDICAL KIT</h1>
        </div>
        <br />
        <h3>Request for a Medical Kit online.</h3>
      </SubTitle>
      <div style={{ width: "100%" }}>
        <center>
          <p>
            <b>Mini Medical Kit Combo – $1500.00 USD </b>
            <br />
            Tactical Rip-Away EMT Pouch with 84 Piece Improved First Aid Kit
            (IFAK) The Military IFAK Level 1 First Aid Kit pouch features a
            “Rip-Away” capability which allows a soldier to quickly transfer the
            gear to another soldier in an emergency situation without the need
            to unfasten the Molle straps. The pouch has a trifold design with
            various inner pouches and pockets, this allows the kits 84 items to
            be neatly organized and secured.
            <br />
            <b>Contents:</b>
            <br />
            Pouch 1 – Condor Brand Rip-Away EMT Pouch #MA41, Disinfectants 1 –
            Bar of Soap (1/2 oz), 8 – Alcohol Pads, 4 – Iodine Pads ,4 – BZK
            Wipes, Medicine 4 – Aspirin Tablets ,4 – Non-Aspirin Tablets, 2 –
            Ibuprofen Tablets, 1 – Ammonia Inhalant ( Smelling Salts), 1 – Pill
            Bottle Wound Care 2 – Bacitracin, 2 – Triple Antibiotic Ointment, 1
            – Instant Ice Pack Bandages 18 – Sheer Bandages 1″ x 3″ , 12 –
            Fabric Bandages 1″ x 3″ , 1 – Large Triangle Bandage 40″ x 40″ x 56″
            2 – Elastic Bandages 2″ Gauze 2 – Sterile Gauze Pad 12ply 4″ x 4″ ,
            4 – Sterile Gauze Pad 12ply 3″ x 3″ , 3 – Combine Gauze Pad (ABD)
            12ply 5″ x 9″ Gauze Rolls 1 – Gauze Bandage Roll 4″ x 4.1yds, 1 –
            Gauze Bandage Roll 2″ x 4.1yds MISC 2 – Surgical Paper Tape 1/2″ x
            10yds, 1 – EMT Shears 5″, 1 – Tourniquet 1″ x 18″, 1 – Pair of
            Nitrile Surgical Gloves (Non-Latex) Best Quality. <br />
            <Link to="/contactsus">
              <input
                className="order-btn"
                type="button"
                name=""
                value="Order Now"
                onClick={HandleOrders}
              />
            </Link>
            <br />
            <br />
            <b>Flex Maxi Medical Kit Combo – $2700.00 USD</b> <br />
            This GI style issued kit folds out three ways for easy and quick
            access to all your first aid supplies. <br />
            <b>Contents:</b>
            <br />1 Blood Pressure Cuff Kit, 1 Cervical Collar, 1 Pen Light, 2
            Suture Sets, 8 Latex Examination Gloves (pair), 10 Abdominal Pads 5″
            x 9″, 20 Pain Relievers, 6 Safety Pins, 2 Emergency Blankets – 52″ x
            84″ Silver, 1 Hand Sanitizer – 2 Oz, 1 Calamine Lotion – 6 Oz, 1
            Hand Soap, 6 After Bite Wipes, 1 SAM/Universal Splint, 1 Elastic
            Bandage – 6″, 5 Sterile Sponges – 4″ x 4″, 2 Adhesive Tape Rolls, 2
            Bleedstop Bandages, 5 Eye Pads, 2 Triangular, Bandages – 40″ x 40″ x
            56″, 1 Tourniquet, 16 Bandage Strips – 1″ x 3″, 5 Butterfly Strips,
            5 Bandage Strips – 2″ x 3″, 10 Knuckle Bandages 2 Instant Ice Pack,
            1 EMT Shears, 2 Stainless Steel Hempstead, 1 Tweezers, 1 Scalpel
            Handle #3, 2 Scalpel Blades, 1 Stethoscope, 5 Tongue Depressors , 1
            CPR Mask, 45 Antiseptic BZK Wipes, 5 Ammonia Inhalants, 2 Airways, 3
            Multi Trauma Dressings – 12″ x 30″, 1 Skin and Eye Wash, 10 Triple
            Antibiotic Packages, 5 Burn Aid Packages, 1 EFA First Aid Book{" "}
            <br />
            <Link to="/contactsus">
              <input
                className="order-btn"
                type="button"
                name=""
                value="Order Now"
                onClick={HandleOrders}
              />
            </Link>
            <br />
            <br />
            <b>King Medical Kit Combo – $3600.00 USD </b>
            <br />
            First Aid Kit Large M17 Medic Bag. Used by combat trained medics.
            Comes in an OD green XL nylon pack (measuring approximately 13″ x
            13″ x 10″) with hand carry straps. Closes securely using four
            adjustable chinch straps. The bag rolls open to reveal its contents
            contained within one of its six individual interior pockets inside
            its three-fold design. There is also one exterior pocket that is
            secured by a Velcro flap. Contains over 300 items including an
            assortment of bandages, wraps, ointments, medicine, ice packs,
            syringes, latex gloves, safety pins, pill bottles, tape, cotton
            tips, hemostats, sponges, airways, wipes, inhalants and suture sets.
            Kit also includes a CPR mask, tongue depressor, thermometer, first
            aid book, arm splint, blood stopper kit, pen light, EMT shears,
            tweezers, skin probe and scalpel. Excellent kit for home, office or
            vehicle. Weighs 15 lbs. Contents may vary slightly.
            <br />
            <b>Contents:</b>
            <br /> 1 EFA First Aid Book, 20 Pill Bottles, 100 Bandage Strips,
            1″x3″, 14 Pain Relievers, 10 Bandage Strips, 2″x4″, 4 Triple
            Antibiotic Packages, 10 Knuckle Bandages, 4 First Aid Cream
            Packages, 10 Butterfly Strips, 2 Burn Aid Packages, 4 Bandage
            Gauzes, 2″x6 Yards, 1 SAM/Universal Splint, 4 Elastic Bandages, 6″,
            1 EMT Shears, 7.25″, 2 Triangular Bandage, 1 Pair of Tweezers, 3
            Field Dressings, 1 Stethoscope, 4 Multi Trauma Dressings 12″x30″, 1
            Tourniquet, 1 Blood Stopper Kit, 1 Scalpel handle #3, 4 Sterile
            Pads, 4″x4″, 2 Scalpel Baldes 10 Sterile Pads, 2″x2″, 1 Skin Probe,
            10 Abdominal Pads, 5″x9″, 2 Stainless Steel homestat, 2 Eye Pads, 1
            Pen Light, 15 Alcohol Wipes, 2 Suture Sets 15 Iodine Wipes, 5 Tape,
            Rolls, Adhesive, 1″, 15 Antiseptic BZK Wipes 2 Tongue Depressors ,
            15 Clean Wipes 2 Airways, 6 After Bite Wipes, 2 Ammonia Inhalants, 1
            Burn Spray, 1 CPR Mask, 1 Petroleum jelly, 2 Irrigation Syringes, 1
            Lip Treatment 3 Instant Ice Packs, 1 Calamine Lotion, 6 oz., 6
            Safety Pins, 1 Sterile Flushing Solution, 1 Box of Sponges, 4″ x4″,
            1 Hand Sanitizer, 1 Box of 100 Cotton Tips, 1 Hand Soap, 6 Pairs
            Latex Examination Gloves
            <br />
            <Link to="/contactsus">
              <input
                className="order-btn"
                type="button"
                name=""
                value="Order Now"
                onClick={HandleOrders}
              />
            </Link>
            <br />
            <br />
          </p>
        </center>
      </div>
    </Wrapper>
  );
};

export default MedicalKit;
